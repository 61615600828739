/*
 * CSS
 */
.planDeliveryInformation {
  & > p.description {
    margin: 0 !important;
  }
  & > div > .subHeading {
    background-color: var(--chakra-colors-gray-100);
    box-shadow: unset !important;
    color: var(--chakra-colors-gray-500);
  }
  & .chakra-checkbox__control {
    background: var(--chakra-colors-gray-300);
    border: 1px solid var(--chakra-colors-gray-300);
    border-radius: 4px;
    box-shadow: unset !important;
    color: var(--chakra-colors-gray-500);
  }
  & .prototype {
    & .chakra-checkbox__control {
      background: var(--chakra-colors-primary-700);
      border: 1px solid var(--chakra-colors-primary-700);
    }
  }
}