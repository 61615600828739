/*
 * CSS
 */

 .gradientWrapper {
    position: fixed;
    z-index: -1;
  }
  
  
  .sphere {
      width: 80px !important;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      align-self: flex-end;
      height: 80px !important;
      position: relative;
      @media (max-width: 768px) {
        width: 100%;
        left: 0;
      }
      & canvas {
        border: unset !important;
        width: 100% !important;
        height: 100% !important;
      }
      & > div:first-child {
        top: 0;
        left: 0;
        width: 80px !important;
        height: 80px !important;
        border-radius: 12px;
        position: relative;
        &.sphereLoaded {
          &:before {
            content: unset;
          }
        }
      }
    }
  