/*
 * CSS
 */
 .studioAppInformation {
    .description {
      margin-top: 32px !important;
    }
  }

  input.styled-input {
    height: 50px;
    background-color: var(--chakra-colors-primary-500);
    border: none;
    color: white;
    border-radius: 16px;
    border-color: gray.300;
}

input.styled-input::placeholder {
    color: white;
}

select.styled-select {
  height: 50px;
  background-color: var(--chakra-colors-primary-500);
  border: none;
  color: white;
  border-radius: 16px;
  border-color: gray.300;
}

select.styled-select::placeholder {
  color: white;
}