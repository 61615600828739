/*
 * CSS
 */
.studio {
  .description {
    margin-top: 32px !important;
  }
  & .studioAppInformation > div > .subHeading {
    background-color: var(--chakra-colors-gray-100);
    box-shadow: unset !important;
    color: var(--chakra-colors-gray-500);
  }
  & .greeting {
    margin: 0 !important;
    z-index: 10;
    position: relative;
  }
  .gradientWrapper {
    position: fixed;
    z-index: -1;
  }
  & .sphereWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-self: flex-end;
    top: 120px;
    position: relative;
    @media (max-width: 768px) {
      width: 100%;
      left: 0;
    }
    & canvas {
      border: unset !important;
      width: 100% !important;
      height: 100% !important;
    }
    & > div:first-child {
      top: 0;
      left: 0;
      width: calc(100vw / 4);
      height: calc(100vh / 2);
      border-radius: 12px;
      position: relative;
      &.sphereLoaded {
        &:before {
          content: unset;
        }
      }
    }
  }
  & .stepContainer {
    & .subHeading.inactive {
      background-color: var(--chakra-colors-gray-100);
      box-shadow: unset !important;
      color: var(--chakra-colors-gray-500);
    }
  }
}