/*
 * CSS
 */
.mdConversation {
  position: relative;
  width: 100%;
  & .markDownContainer {
    width: 60%;
    padding: 10px 0;
    & img {
      margin: 32px 0;
    }
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--chakra-colors-black);
      font-family: "Poppins", monospace;
    }
    p {
      font-size: 17px;
      letter-spacing: .4px;
      line-height: 2.3;
      font-family: "Space Mono", monospace;
    }
    & .heading {
      margin-bottom: 0;
      &.weight900 {
        font-weight: 900;
      }
    }
    & .heading~* {
      position: relative;
      margin-top: 0;
      margin-bottom: 42px;

      &~.subHeading {
        padding: 6px 22px;
      }
    }

    & a {
      font-weight: 600;
      font-size: 14px;
      color: var(--chakra-colors-white);
      position: relative;
      width: max-content;

      &::before {
        content: '';
        width: 100%;
        height: 2px;
        left: 5px;
        bottom: -2.5px;
        position: absolute;
        z-index: -1;
        background-color: var(--chakra-colors-black);
      }
    }

    & li {
      position: relative;
      left: 15px;
      color: var(--chakra-colors-black);
    }

    & * {
      margin-bottom: 20px;
      font-family: "Space Mono", monospace;
    }

    & tr {
      background-color: var(--chakra-colors-black);

      & td,
      & th {
        text-align: left;
        padding: 10px;
        border-bottom: 2px solid var(--chakra-colors-gray-100);
        color: var(--chakra-colors-black);
      }
    }

    & code>span {
      display: unset;
    }

    & pre:has(> code) {
      border-radius: 18px;
      background-color: var(--chakra-colors-gray-50) !important;
      & [class^='token'] {
        font-family: "Space Mono", monospace;
        font-size: 18px;
        font-weight: 500;
      }
    }

    &>h1:first-child {
      font-size: 41px;
      font-weight: 900;
    }
  }
}