/*
 * CSS
 */
 *,
 *::before,
 *::after {
   box-sizing: inherit;
 }
 
 *:focus {
   outline: none;
 }
 
 ::selection {
   background: var(--chakra-colors-gray-50);
   color: var(--chakra-colors-white);
 }
 
 ::-webkit-scrollbar {
   width: 0;
   background: transparent;
 }
 
 ::-webkit-scrollbar-thumb {
   background: transparent;
 }
 
 html {
   box-sizing: border-box;
 }
 
 body {
   margin: 0;
   padding: 0;
   overflow-x: hidden;
   tap-highlight-color: transparent;
   letter-spacing: 0.5px;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-family: 'Poppins', Helvetica, Arial, sans-serif;
   background-color: var(--chakra-colors-black);
 }
 
 #__next {
   position: relative;
   overflow-x: hidden;
 }
 
 ul {
   margin: 0;
   padding: 0;
 }
 
 li {
   list-style-type: none;
 }
 
 a {
   text-decoration: none;
 
   &:hover {
     text-decoration: none;
   }
 }
 
 @keyframes blinkHide {
   0% {
     opacity: 0;
   }
 
   10% {
     opacity: 1;
   }
 }
 
 @keyframes blinkShow {
   0% {
     opacity: 1;
   }
 
   10% {
     opacity: 0;
   }
 }
 
 @keyframes rotateIt {
   to {
     transform: rotate(-360deg);
   }
 }
 
 @keyframes moveUpAndDownArrow {
 
   0%,
   100% {
     transform: translateY(-2.5px);
   }
 
   50% {
     transform: translateY(2.5px);
   }
 }
 
 @keyframes fadeIn {
   100% {
     transform: scale(1.03);
     opacity: 0;
   }
 }
 
 @keyframes scaleAndRotateArrow {
   from {
     opacity: 0;
     transform: rotate(0deg);
   }
 
   to {
     opacity: 1;
     transform: rotate(-120deg);
   }
 }
 
 @keyframes moveArrowForwardAndBackward {
   from {
     right: -5px;
   }
 
   to {
     right: 5px;
   }
 }
 
 @keyframes animateInTopLeft {
   from {
     transform: translate3d(0, 0, 0);
   }
 
   to {
     transform: translate3d(5px, -5px, -5px);
   }
 }
 
 @keyframes animeOutTopLeft {
   from {
     transform: translate3d(5px, -5px, -5px);
   }
 
   to {
     transform: translate3d(0, 0, 0);
   }
 }
 
 .reusecore__button {
   width: max-content;
   margin-top: 30px !important;
   margin-bottom: 30px !important;
   margin-left: 0;
   margin-right: 0;
   font-size: 15px;
   display: flex;
   border-radius: 9px;
   border-color: transparent;
   background: var(--chakra-colors-primary-500);
   padding: 15px 20px;
   text-transform: capitalize;
   transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;
   box-shadow: 0 20px 35px rgba(0, 0, 0, 0.23);
   pointer-events: auto;
   cursor: pointer;
   position: relative;
 
   &::before,
   &::after {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
   }
 
   & * {
     font-weight: 600;
     color: var(--chakra-colors-white);
   }
 
   &:hover {
     background: transparent !important;
     box-shadow: none;
   }
 
   &.white {
     background: var(--chakra-colors-white);
     box-shadow: 0 20px 35px rgba(0, 0, 0, 0.23);
 
     & * {
       font-weight: 600;
       color: var(--chakra-colors-black);
     }
 
     &:hover {
       background: var(--chakra-colors-white);
     }
   }
 
   &.black {
     background: var(--chakra-colors-black);
     box-shadow: 0 20px 35px rgba(0, 0, 0, 0.23);
 
     & * {
       font-weight: 600;
       color: var(--chakra-colors-white);
     }
   }
 
   &.theme {
     background: var(--chakra-colors-primary-500);
     box-shadow: 0 20px 35px rgba(0, 0, 0, 0.23);
 
     & * {
       font-weight: 600;
       color: var(--chakra-colors-white);
     }
 
     &.secondary {
       background: var(--chakra-colors-ternary-500);
       box-shadow: 0 20px 35px rgba(0, 0, 0, 0.23);
 
       & * {
         font-weight: 600;
         color: var(--chakra-colors-white);
       }
     }
   }
 
   &.success {
     background: var(--chakra-colors-green-500);
 
     & * {
       font-weight: 600;
       color: var(--chakra-colors-green-500);
     }
   }
 
   &.danger {
     background: var(--chakra-colors-red-500);
 
     & * {
       font-weight: 600;
       color: var(--chakra-colors-white);
     }
   }
 
   &.gradientRound,
   &.dangerRound {
     font-size: 15px;
     border: none;
     display: flex;
     font-weight: 600;
     margin: auto;
     gap: 5px;
     background: transparent;
     align-items: center;
     border-radius: 25px !important;
 
     & span {
       color: var(--chakra-colors-white);
 
       & svg {
         fill: var(--chakra-colors-white);
       }
     }
 
     &:hover {
       &:after {
         filter: opacity(1.0);
         transform: scale(1.1);
       }
 
       & svg {
         position: relative;
         animation: moveArrowForwardAndBackward 1s cubic-bezier(.19, 1, .22, 1) infinite alternate;
       }
     }
 
     &:after {
       content: ' ';
       background: linear-gradient(90deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);
       width: 100%;
       height: 100%;
       left: 0;
       top: 0;
       z-index: -1;
       border-radius: 25px !important;
       filter: opacity(0.9);
       transition: transform .5s cubic-bezier(.19, 1, .22, 1), opacity .5s cubic-bezier(.19, 1, .22, 1);
     }
   }
 
   &.dangerRound {
     background: var(--chakra-colors-red-500);
 
     & span {
       color: var(--chakra-colors-white);
 
       & svg {
         fill: var(--chakra-colors-white);
       }
     }
 
     &:after {
       background: linear-gradient(90deg, var(--chakra-colors-red-500) 0%, var(--chakra-colors-red-500) 65.1%, var(--chakra-colors-red-500) 100%);
     }
   }
 }
 
 .subHeading {
   text-transform: uppercase;
   justify-content: center;
   margin-right: auto;
   margin-bottom: 0;
   margin-left: 0;
   position: relative;
   text-align: left;
   font-size: 13px;
   width: min-content;
   font-weight: 600;
   box-shadow: 0 20px 35px rgba(0, 0, 0, 0.23);
   background-color: var(--chakra-colors-primary-500);
   border-radius: 25px;
   padding: 2.5px 10px !important;
   color: var(--chakra-colors-white);
 
   &.revert {
     &::before {
       left: unset;
       right: -30px;
     }
   }
 
   &.transparent {
     padding: 0 !important;
     background-color: transparent;
 
     &::before {
       background-color: transparent;
     }
 
     &.pureWhite {
       color: var(--chakra-colors-white) !important;
     }
 
     &.followedLine,
     &.danger {
       &::after {
         content: ' ';
         width: 135px;
         height: 0;
         margin-left: 10px;
         background-clip: text;
         text-fill-color: transparent;
         border-bottom: 2px solid transparent;
         border-image: linear-gradient(90deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);
         border-image-slice: 1;
       }
     }
 
     &.danger {
       &.subHeading {
         background-color: unset;
       }
 
       &::after {
         border-image-slice: 1 !important;
         border-image: linear-gradient(90deg, var(--chakra-colors-red-500) 0%, var(--chakra-colors-red-500) 65.1%, var(--chakra-colors-red-500) 100%);
       }
     }
   }
 
   &.white {
     background-color: var(--chakra-colors-white);
     color: var(--chakra-colors-black) !important;
 
     &::before {
       background-color: var(--chakra-colors-white);
     }
   }
 
   &.theme {
     background-color: var(--chakra-colors-primary-500);
     color: var(--chakra-colors-white) !important;
     box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
 
     &::before {
       background-color: var(--chakra-colors-primary-500);
       box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
     }
 
     &.secondary {
       background-color: var(--chakra-colors-ternary-500);
       color: var(--chakra-colors-white) !important;
       box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
 
       &::before {
         background-color: var(--chakra-colors-ternary-500);
         box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
       }
     }
   }
 
   &.black {
     background-color: var(--chakra-colors-black);
     color: var(--chakra-colors-white);
     box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
 
     &::before {
       background-color: var(--chakra-colors-black);
       box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
     }
   }
 
   &.pureBlack {
     background-color: var(--chakra-colors-black);
     color: var(--chakra-colors-white) !important;
     box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
 
     &::before {
       background-color: var(--chakra-colors-black);
       box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
     }
   }
 
   &.danger {
     background-color: var(--chakra-colors-red-500);
     color: var(--chakra-colors-white) !important;
     box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
 
     &::before {
       background-color: var(--chakra-colors-red-500);
       box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.23);
     }
   }
 
   @media (max-width: 766px) {
     font-size: 14px;
   }
 
   @media (max-width: 556px) {
     font-size: 13px;
   }
 }
 
 .heading,
 .selfWritingHeading {
   font-size: 70px;
   font-weight: 500 !important;
   letter-spacing: 0.015em;
   line-height: 1.2;
   text-align: left;
   margin-top: 0 !important;
   margin-right: 0;
   width: auto;
   margin-bottom: 0;
   position: relative;
   margin-left: 0;
   color: var(--chakra-colors-primary-500);
 
   &.danger {
     color: var(--chakra-colors-red-500);
   }
 
   @media (max-width: 1300px) and (min-width: 766px) {
     font-size: 81px;
   }
 
   @media (max-width: 771px) {
     font-size: 71px;
   }
 
   @media (max-width: 661px) {
     font-size: 31px;
   }
 }
 
 .selfWritingHeading {
   font-weight: 200;
 }
 
 *~.subHeading,
 .subHeading~* {
   margin-top: 10px !important;
 }
 
 .subHeading~.heading {
   margin-top: 10px !important;
 }
 
 .description {
   font-size: 18px;
   line-height: 1.75;
   font-weight: 400;
   text-align: left;
   width: 60%;
   margin-top: 40px !important;
   margin-right: auto;
   margin-bottom: 0;
   margin-left: 0;
   color: var(--chakra-colors-black);
 
   @media (max-width: 1220px) {
     width: 70%;
   }
 
   @media (max-width: 661px) {
     width: 100%;
   }
 }
 
 .description~.reusecore__button {
   margin-top: 60px !important;
 }
 
 .svg {
   &.stroke {
     &.theme {
       stroke: var(--chakra-colors-primary-500);
 
       &.secondary {
         stroke: var(--chakra-colors-ternary-500);
       }
     }
 
     &.white {
       stroke: var(--chakra-colors-white);
     }
 
     &.blackShadow {
       stroke: rgba(0, 0, 0, 0.23);
     }
 
     &.darkWhite {
       stroke: var(--chakra-colors-white-200);
     }
 
     &.lightDarkWhite {
       stroke: var(--chakra-colors-white-100);
     }
   }
 
   &.fill {
     &.theme {
       fill: var(--chakra-colors-primary-500);
 
       &.secondary {
         fill: var(--chakra-colors-ternary-500);
       }
     }
 
     &.white {
       fill: var(--chakra-colors-white);
     }
 
     &.blackShadow {
       fill: rgba(0, 0, 0, 0.23);
     }
 
     &.darkWhite {
       fill: var(--chakra-colors-white-200);
     }
 
     &.lightDarkWhite {
       fill: var(--chakra-colors-white-100);
     }
   }
 }
 
 .font.whiteSpacePre {
   white-space: pre !important;
 }
 
 .font.weight200 {
   font-weight: 200 !important;
 }
 
 .font.weight300 {
   font-weight: 300 !important;
 }
 
 .font.weight400 {
   font-weight: 400 !important;
 }
 
 .font.weight500 {
   font-weight: 500 !important;
 }
 
 .font.weight900 {
   font-weight: 900 !important;
 }
 
 .font.strikeThrough {
   text-decoration: line-through;
 }
 
 .font.underline {
   text-decoration: underline;
 }
 
 .font.black {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-black);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-black);
     }
   }
 }
 
 .font.white {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-white);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-white);
     }
   }
 }
 
 .font {
   &.onHoverGradient {
     &:hover {
       background: linear-gradient(90deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       background-clip: text;
       text-fill-color: transparent;
     }
   }
 }
 
 .font.white {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-white);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-white);
     }
   }
 }
 
 .font.darkerWhite {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-white-200);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-white-200);
     }
   }
 }
 
 .font.danger {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-red-500);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-red-500);
     }
   }
 }
 
 .font.pureWhite {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-white);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-white);
     }
   }
 }
 
 .font.theme {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-primary-500);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-primary-500);
     }
   }
 }
 
 .font.theme.secondary {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     color: var(--chakra-colors-ternary-500);
   }
 
   &.subHeading {
     &::before {
       background-color: var(--chakra-colors-ternary-500);
     }
   }
 }
 
 .font.gradient {
 
   span&,
   a&,
   p&,
   h1&,
   h2&,
   h3&,
   h4&,
   h5&,
   h6&,
   span,
   a,
   p,
   h1,
   h2,
   h3,
   h4,
   h5,
   h6,
   &.selfWritingHeading {
     background: linear-gradient(90deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     background-clip: text;
     text-fill-color: transparent;
 
     &.topToBottom {
       background: linear-gradient(180deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       background-clip: text;
       text-fill-color: transparent;
     }
   }
 
   &.subHeading {
     &::before {
       background: linear-gradient(90deg, var(--chakra-colors-primary-500) 0%, var(--chakra-colors-secondary-500) 65.1%, var(--chakra-colors-ternary-500) 100%);
       -webkit-background-clip: text;
       -webkit-text-fill-color: transparent;
       background-clip: text;
       text-fill-color: transparent;
     }
   }
 }
 
 svg.hideSVG {
   display: none !important;
 }
 
 .width.maxContent {
   width: max-content;
 }
 
 .width.minContent {
   width: min-content;
 }
 
 .font.capitalize {
   text-transform: capitalize;
 }
 
 .strike {
   text-decoration: line-through;
 }
 
 div.scrollbar-track.scrollbar-track-x.show,
 div.scrollbar-track.scrollbar-track-y.show {
   display: none !important;
 }
 
 .gradientWrapper {
   width: 100%;
   height: 100%;
   position: relative;
 
   & .gradient {
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     z-index: -10;
 
     &.color.theme {
       --gradient-color-1: var(--chakra-colors-primary-500);
       --gradient-color-2: var(--chakra-colors-white);
       --gradient-color-3: var(--chakra-colors-ternary-500);
       --gradient-color-4: var(--chakra-colors-white);
       --gradient-color-5: var(--chakra-colors-white);
     }
 
     &.color.danger {
       --gradient-color-1: var(--chakra-colors-red-500);
       --gradient-color-2: var(--chakra-colors-white);
       --gradient-color-3: var(--chakra-colors-red-500);
       --gradient-color-4: var(--chakra-colors-white);
       --gradient-color-5: var(--chakra-colors-white);
     }
 
     &.color.blackAndPrimary {
       --gradient-color-1: var(--chakra-colors-primary-500);
       --gradient-color-2: var(--chakra-colors-white);
     }
 
     &.color.blackAndSecondary {
       --gradient-color-1: var(--chakra-colors-ternary-500);
       --gradient-color-2: var(--chakra-colors-white);
     }
   }
 
   & .overlay {
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     left: 0;
     z-index: -1;
 
     &:after {
       content: '';
       display: block;
       height: 100%;
       position: absolute;
       top: 0;
       width: 100%;
       box-shadow: inset 0 0 100px 200px var(--chakra-colors-white), 0 0 100px 200px rgba(255, 255, 255, 0.03);
     }
   }
 
   &.topLeft,
   &.topRight,
   &.bottomLeft,
   &.bottomRight {
     & .gradient {
       left: -20vh;
       top: -20vh;
       z-index: -10;
     }
 
     & .overlay {
       left: -20vh;
       top: -20vh;
       z-index: -1;
       width: 100vh;
       height: 100vh;
     }
 
     &.round {
       & canvas {
         height: 100vh;
         width: 100vh;
         border-radius: 100%;
         overflow: hidden;
       }
 
       & .overlay {
         width: 100vh;
         height: 100vh;
         border-radius: 100%;
       }
     }
   }
 
   &.topRight,
   &.bottomRight {
     & .gradient {
       right: -20vh;
       left: unset;
     }
 
     & .overlay {
       right: -20vh;
       left: unset;
     }
   }
 
   &.bottomLeft,
   &.bottomRight {
     & .gradient {
       top: unset;
       bottom: -20vh;
     }
 
     & .overlay {
       top: unset;
       bottom: -20vh;
     }
   }
 }
 
 .position {
   &.unset {
     position: unset !important;
   }
 
   &.relative {
     position: relative;
   }
 }
 
 code {
   transform: translateZ(0);
   min-width: 100%;
   float: left;
 
   &>span {
     display: block;
   }
 }
 
 & .popup-content {
   margin: auto;
   background: var(--chakra-colors-black);
   height: 100vh;
   width: 100vw;
   padding: 50px 20px;
   overflow-y: scroll !important;
   -webkit-animation: anvil 0.4s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
 }
 
 .component>section {
   overflow: unset !important;
 }
 
 .component>div {
   height: 100% !important;
 }
 
 svg.textFollowPath {
   width: 100%;
   height: auto;
   overflow: visible;
   position: absolute;
   top: 25%;
   z-index: -1;
 }
 
 svg.textFollowPath path {
   fill: transparent;
   stroke: var(--chakra-colors-black);
   stroke-width: 1px;
 }
 
 svg.textFollowPath text {
   font-size: 10px;
   fill: #FFFFFF;
 }

 #SideMenuGroot canvas {
  width: 100%;
  height: 100%;
}